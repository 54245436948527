<template>
	<div class="refferperformance">
		<el-tabs v-model="listState" @tab-click="filterFun">
			<el-tab-pane v-for="item in listStateList" :key="item.id" :label="item.value" :name="item.id+''"></el-tab-pane>
		</el-tabs>
		<div class="filter-container">
			<div class="filter-item">
				<label class="label">关键字:</label>
				<el-input v-model="searchKey" style="margin-left:5px;width:300px;" placeholder="推手姓名、微信昵称、手机号" clearable></el-input>
			</div>
			<div class="filter-item">
				<label class="label">时间筛选:</label>
				<el-date-picker type="datetime" placeholder="选择日期" v-model="starTime" style="width: 250px;" value-format="yyyy-MM-dd HH:mm:ss"
				 format="yyyy-MM-dd HH:mm:ss"></el-date-picker> ~
				<el-date-picker type="datetime" placeholder="选择日期" v-model="endTime" style="width: 250px;" value-format="yyyy-MM-dd HH:mm:ss"
				 format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
			</div>
			<div class="filter-item">
				<el-button type="primary" style="margin-left:30px" @click="handleFilter">查询</el-button>
				<el-button style="margin-left:30px;width:90px;" @click="exportFun">导出</el-button>
			</div>
		</div>
		<div class="table-container">
			<el-table :data="tableData" style="width: 100%" v-loading="loading">
				<el-table-column key="1" label="推手" width="200px">
					<template slot-scope="scope">
						<div style="display: flex;align-items: center;">
							<img :src="scope.row.WxHeadUrl ? (scope.row.WxHeadUrl) : defaultHeader" style="width: 50px;height: 50px;border-radius: 100%;">
							<div style="margin-left: 10px">
								<div class="oneFlows">{{scope.row.NameInfo}}</div>
								<div>{{scope.row.Phone}}</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column key="2" prop="DistributRoleName" label="推手等级"></el-table-column>

				<el-table-column key="3" label="推荐人" show-overflow-tooltip :render-header="icons">
					<template slot-scope='scope'>
						<div>{{scope.row.RecommendNameInfo}}</div>
						<div>{{scope.row.RecommendPhone}}</div>
					</template>
				</el-table-column>
				<el-table-column key="4" :label="listState==0?'个人团队业绩':'推荐团队业绩'" prop="ShipMent">
					<template slot-scope='scope'>
						<div>{{scope.row.ShipMent}}</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="150px" key='5'>
					<template slot-scope="scope">
						<el-button type="text" @click="achievementDetail(scope.row)">团队业绩详情</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div style="display:flex;justify-content:space-between;align-items:center;padding-bottom: 20px;">
				<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;"></div>
				<el-pagination v-if="Total" style="margin-top:20px;float:right;" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				 :total="Total"></el-pagination>
			</div>
		</div>

		<el-dialog :visible.sync="achievementShow" width="70%" :title="detailTitle + '业绩详情'">
			<div class="filter-container">
				<div class="filter-item">
					<label class="label">关键字:</label>
					<el-input v-model="detailsearchKey" style="margin-left:5px;width:300px;" :placeholder="detailplaceholder"
					 clearable></el-input>
				</div>
				<div class="filter-item">
					<el-button type="primary" style="margin-left:30px" @click="handleFilterdetail">查询</el-button>
					<el-button style="margin-left:30px;width:90px;" @click="exportFundetail">导出</el-button>
				</div>
			</div>

			<div style="color: red;margin-bottom: 30px;" v-if="listState == 0">业绩总计：{{AllShipMent}}</div>
			<!-- //个人团队业绩详情 -->
			<div class="table_container" v-if="listState == 0">
				<el-table :data="tablepersonData" style="width: 100%" v-loading="loading">
					<el-table-column key="1" label="订单号">
						<template slot-scope="scope">
							<div>{{scope.row.OrderNo}}</div>
						</template>
					</el-table-column>
					<el-table-column key="2" prop="PayTime" label="支付时间">
						<template slot-scope="scope">
							<div>{{scope.row.PayTime}}</div>
						</template>
					</el-table-column>
					<el-table-column key="3" label="完成时间">
						<template slot-scope='scope'>
							<div>{{scope.row.ReceiveTime}}</div>
						</template>
					</el-table-column>
					<el-table-column key="4" label="售出人" prop="Name">
						<template slot-scope='scope'>
							<div>{{scope.row.Name?scope.row.Name:scope.row.WxNickname}}</div>
						</template>
					</el-table-column>
					<el-table-column key="5" label="售出人手机号" prop="Phone">
						<template slot-scope='scope'>
							<div>{{scope.row.Phone}}</div>
						</template>
					</el-table-column>
					<el-table-column key="6" label="业绩" prop="ShipMent">
						<template slot-scope='scope'>
							<div>{{scope.row.ShipMent}}</div>
						</template>
					</el-table-column>
					<el-table-column key="7" label="下单人" prop="CustomerName">
						<template slot-scope='scope'>
							<div>{{scope.row.CustomerName ? scope.row.CustomerName : scope.row.CustomerWxNickname}}</div>
						</template>
					</el-table-column>
					<el-table-column key="8" label="下单人手机号" prop="CustomerPhone">
						<template slot-scope='scope'>
							<div>{{scope.row.CustomerPhone}}</div>
						</template>
					</el-table-column>
				</el-table>
				<div style="display:flex;justify-content:space-between;align-items:center;padding-bottom: 20px;">
					<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;"></div>
					<el-pagination v-if="Total1" style="margin-top:20px;float:right;" @size-change="handleSizeChange1" @current-change="handleCurrentChange1"
					 :current-page="currentPage1" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize1" layout="total, sizes, prev, pager, next, jumper"
					 :total="Total1"></el-pagination>
				</div>
			</div>
			<!-- //推荐团队业绩详情 -->
			<div class="table_container" v-if="listState == 1">
				<el-table :data="tableTeamData" style="width: 100%" v-loading="loading">
					<el-table-column key="1" label="推手">
						<template slot-scope="scope">
							<div>{{scope.row.Name ? scope.row.Name : scope.row.WxNickname}}</div>
						</template>
					</el-table-column>
					<el-table-column key="2" prop="Phone" label="推手手机号">
						<template slot-scope="scope">
							<div>{{scope.row.Phone}}</div>
						</template>
					</el-table-column>
					<el-table-column key="3" label="TA的个人团队业绩">
						<template slot-scope='scope'>
							<div>{{scope.row.ShipMent}}</div>
						</template>
					</el-table-column>
				</el-table>
				<div style="display:flex;justify-content:space-between;align-items:center;padding-bottom: 20px;">
					<div style="display:flex;font-size: 12px;color: #606266;align-items:center;margin-left: 13px;"></div>
					<el-pagination v-if="Total2" style="margin-top:20px;float:right;" @size-change="handleSizeChange2" @current-change="handleCurrentChange2"
					 :current-page="currentPage2" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize2" layout="total, sizes, prev, pager, next, jumper"
					 :total="Total2"></el-pagination>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import config from "@/config/index";
	import {
		memberTeamShiplist,
		memberRecommendTeamShiplist,
		memberTeamShipDetail,
		memberRecommendTeamShipDetail
	} from "@/api/TurnTomySelf"
	export default {
		data() {
			return {
				MemberId:'',
				exportId: '',
				AllShipMent: 0,
				detailsearchKey: '',
				detailplaceholder: '订单号、售出人、售出人手机号',
				achievementShow: false,
				detailTitle: '',
				defaultHeader: config.DEFAULT_HEADER,
				listState: "0",
				listStateList: [{
						id: 0,
						value: "个人团队业绩"
					},
					{
						id: 1,
						value: "推荐团队业绩"
					}
				],
				currentPage: 1,
				pageSize: 20,
				Total: 0,
				tableData: [],
				exportUrl: config.EXPORT_URL,
				searchKey: '',
				starTime: '',
				endTime: '',
				loading: false,


				currentPage1: 1,
				pageSize1: 20,
				Total1: 0,
				tablepersonData: [],

				tableTeamData: [],
				currentPage2: 1,
				pageSize2: 20,
				Total2: 0,
			}
		},
		created() {
			this.initTime();
			this.getCurrentMonthLast()
			this.getList()
		},
		methods: {
			icons(h, {
				column
			}) {
				let inReview;
				if (column.label == '推荐人') {
					inReview = '推手当前的推荐人信息'
				}
				return h(
					'div', [
						h('span', column.label),
						h('el-tooltip', {
							props: {
								placement: 'top'
							}
						}, [
							h('div', {
								slot: 'content',
								style: {
									'width': '130px',
									whiteSpace: 'normal',
									'margin-bottom': '10px'
								}
							}, inReview),
							h('i', {
								class: 'el-icon-warning',
								style: 'margin-left:5px;'
							})
						], )
					]
				)
			},
			// 切换显示条数
			handleSizeChange1(val) {
				this.pageSize1 = val;
				this.getpersonList();
			},
			// 翻页
			handleCurrentChange1(val) {
				this.currentPage1 = val;
				this.getpersonList();
			},
			async getpersonList() {
				try {
					this.loading = true
					let data = {
						MemberId: this.MemberId,
						KeyWords: this.detailsearchKey,
						StartTime: this.starTime,
						EndTime: this.endTime,
						Skip: (this.currentPage1 - 1) * this.pageSize1,
						Take: this.pageSize1,
					}
					let result = await memberTeamShipDetail(data)
					this.tablepersonData = result.Result.Results
					this.AllShipMent = result.Result.AllShipMent
					this.Total1 = result.Result.Total
				} catch (err) {
					this.loading = false
				} finally {
					this.loading = false
				}
			},
			// 切换显示条数
			handleSizeChange2(val) {
				this.pageSize1 = val;
				this.getTeamlist();
			},

			// 翻页
			handleCurrentChange2(val) {
				this.currentPage1 = val;
				this.getTeamlist();
			},
			async getTeamlist() {
				try {
					this.loading = true
					let data = {
						MemberId: this.MemberId,
						KeyWords: this.detailsearchKey,
						StartTime: this.starTime,
						EndTime: this.endTime,
						Skip: (this.currentPage2 - 1) * this.pageSize2,
						Take: this.pageSize2,
					}
					let result = await memberRecommendTeamShipDetail(data)
					this.tableTeamData = result.Result.Results
					this.Total2 = result.Result.Total
				} catch (err) {
					this.loading = false
				} finally {
					this.loading = false
				}
			},
			exportFundetail() {
				//个人团队业绩导出
				if (this.listState == 0) {
					let url = this.exportUrl + '/pc/member/memberTeamShipDetailExport?' +
						'KeyWords=' + (this.detailsearchKey ? encodeURIComponent(this.detailsearchKey) : '') +
						'&StartTime=' + (this.starTime ? this.starTime : '') +
						'&EndTime=' + (this.endTime ? this.endTime : '') +
						'&MemberId=' + this.exportId
					window.open(url);
				} else {
					let url = this.exportUrl + '/pc/member/memberRecommendTeamShipDetailExport?' +
						'KeyWords=' + (this.detailsearchKey ? encodeURIComponent(this.detailsearchKey) : '') +
						'&StartTime=' + (this.starTime ? this.starTime : '') +
						'&EndTime=' + (this.endTime ? this.endTime : '') +
						'&MemberId=' + this.exportId
					window.open(url);
				}
			},
			handleFilterdetail() {
				if (this.listState == 0) {
					this.currentPage1 = 1
					this.getpersonList()
				} else {
					this.currentPage2 = 1
					this.getTeamlist()
				}
			},
			//业绩详情
			achievementDetail(record) {
				try {
					//个人团队详情
					this.achievementShow = true
					this.detailsearchKey = ''
					if (this.listState == 0) {
						this.detailTitle = '个人团队'
						this.MemberId = record.MemberId
						this.getpersonList();
						this.exportId = record.MemberId
						this.detailplaceholder = '订单号、售出人、售出人手机号'
					} else {
						this.detailTitle = '推荐团队'
						this.MemberId = record.Id
						this.getTeamlist()
						this.exportId = record.Id
						this.detailplaceholder = '推手、推手手机号'
					}
				} catch (err) {

				} finally {

				}

			},
			async getList() {
				try {
					this.loading = true
					let data = {
						KeyWords: this.searchKey,
						StartTime: this.starTime,
						EndTime: this.endTime,
						Skip: (this.currentPage - 1) * this.pageSize,
						Take: this.pageSize,
					}
					let result;
					//个人团队业绩
					if (this.listState == 0) {
						result = await memberTeamShiplist(data)
					}
					//推荐团队业绩
					if (this.listState == 1) {
						result = await memberRecommendTeamShiplist(data)
					}
					this.tableData = result.Result.Results
					this.Total = result.Result.Total
				} catch (e) {
					//TODO handle the exception
				} finally {
					this.loading = false
				}
			},
			filterFun() {
				this.searchKey = ''
				this.currentPage = 1;
				this.pageSize = 20;
				this.getList();
			},
			initTime() {
				let date = new Date();
				let year = date.getFullYear();
				let month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1));
				this.starTime = year + '-' + month + '-' + '01' + ' ' + '00:00:00';
			},
			getCurrentMonthLast() {
				var date = new Date();
				var currentMonth = date.getMonth();
				var nextMonth = ++currentMonth;
				var nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1);
				var oneDay = 1000 * 60 * 60 * 24;
				var lastTime = new Date(nextMonthFirstDay - oneDay);
				var month = parseInt(lastTime.getMonth() + 1);
				var day = lastTime.getDate();
				if (month < 10) {
					month = '0' + month
				}
				if (day < 10) {
					day = '0' + day
				}
				this.endTime = date.getFullYear() + '-' + month + '-' + day + ' ' + '23:59:59';
			},
			handleFilter() {
				this.currentPage = 1
				this.getList();
			},
			// 导出
			async exportFun() {
				try {
					this.loading = true;
					//个人团队业绩导出
					if (this.listState == 0) {
						let url = this.exportUrl + '/pc/member/memberTeamShipExport?' +
							'KeyWords=' + (this.searchKey ? encodeURIComponent(this.searchKey) : '') +
							'&StartTime=' + (this.starTime ? this.starTime : '') +
							'&EndTime=' + (this.endTime ? this.endTime : '')
						window.open(url);
					} else {
						let url = this.exportUrl + '/pc/member/memberRecommendTeamShipExport?' +
							'KeyWords=' + (this.searchKey ? encodeURIComponent(this.searchKey) : '') +
							'&StartTime=' + (this.starTime ? this.starTime : '') +
							'&EndTime=' + (this.endTime ? this.endTime : '')
						window.open(url);
					}
				} catch (error) {
					console.log(error);
				} finally {
					this.loading = false;
				}

			},
			// 切换显示条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.getList();
			},
			// 翻页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},
		}
	}
</script>

<style lang="less" scoped>
	.refferperformance {
		background: #fff;
		padding: 15px;
	}
</style>
